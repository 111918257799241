.service-interval {
    display: flex;
    align-items: center;
    margin-top: 6px;
}
.service-interval > span {
    font-weight: bold;
}

.service-interval input,
.service-interval label {
    height: 40px;
    margin-bottom: 0;
}
.service-interval label {
    margin-left: 12px;
    margin-top: 0;
}
.service-interval select {
    height: 42px;
}
.service-interval select,
.service-interval input {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.service-interval label:first-of-type {
    width: 56px;
}
.service-interval label:last-of-type {
    flex-grow: 1;
}
.service-interval .error {
    display: none;
}
.service-interval label {
    width: 40%;
}
