.one-manufacturer {
    display: flex;
    gap: 8px;
}
.one-manufacturer button,
.one-manufacturer input {
    font-size: 14px;
    height: 45px;
    margin-top: 0;
}
.one-manufacturer input {
    height: 41px;
}
button.edit-manufacturers {
    font-size: 12px;
    margin-bottom: 16px;
}
