.print-container {
    max-width: 100vw;
}
.print-container > button{
    margin: 3%;
}
.print-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5%;
}
.print-header .info {
    margin-left: 2%;
}
.print-header p{
    margin: 0;
}
.print-header .qr-code{
    text-align: right;
    flex-grow: 1;
}

.print-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}
.print-content .print-table {
    flex-basis: 48%;
    width: 48%;
    margin: 1%;
}

@media only screen and (max-width: 800px) {
    .print-header {
        margin: 5% 2%;
        flex-direction: column;
    }
    .print-header .logo {
        margin: auto;
    }
    .print-header h3 {
        font-size: 16px;
    }
    .print-header .qr-code{
        display: none;
    }
    .print-content .print-table {
        width: 96%;
        flex-basis: 96%;
        margin: 0 auto 5% auto;
    }
}
