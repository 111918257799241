.status-event {
    display: flex;
}
.status-event label {
    margin-left: 12px;
}
.status-event label:first-child {
    width: 60%;
    margin-left: 0;
}
.status-event label:last-of-type {
    flex-grow: 1;
}
.status-event button {
    background: none;
    border: none;
    margin: 0 16px;
}
