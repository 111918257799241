.app-layout {
    background: #F7F6FF;
    height: 100vh;
    display: flex;
}

.app-layout header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    background: #fff;
}

.app-layout header .mobile-toggle-nav {
    display: none;
    border: 0;
    background: none;
    margin-left: 24px;
}

.app-layout header img {
    margin-left: 22px;
    margin-right: 10px;
}

.app-layout header span {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 500;
    color: #636184;
}

.app-layout header button:last-child {
    font-size: 18px;
    font-weight: 500;
    color: #060047;
    background: none;
    border: none;
    border-left: 1px solid #D9D9D9;
    height: 60px;
    padding: 0 42px;
}

.app-layout nav,
.app-layout .app-content {
    margin-top: 60px;
}

.app-layout nav {
    width: 225px;
    min-width: 225px;
    background: #fff;
    padding-top: 40px;
}

.app-layout nav a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #636184;
    /* font-size: 18px; */
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
    margin-bottom: 20px;
}

.app-layout nav a span {
    margin-left: 8px;
    white-space: nowrap !important;
    display: flex;
}

.app-layout nav a span .count {
    margin-left: 8px;
    font-size: 12px;
    color: #555;
}

.app-layout nav a.active {
    color: #10086D;
    font-weight: bold;
}

.app-layout nav a.active svg path {
    fill: #10086D;
}

.app-layout .app-content {
    flex-grow: 1;
    padding: 24px;
}

.app-layout .app-content h1 {
    margin-top: 0;
}

@media only screen and (max-width: 992px) {
    .app-layout .app-content {
        padding: 12px;
    }

    .app-layout header {
        justify-content: space-between;
    }

    .app-layout header .mobile-toggle-nav {
        display: block;
    }

    .app-layout header img,
    .app-layout header span {
        display: none;
    }

    .app-layout nav {
        z-index: 100;
        position: fixed;
        left: -225px;
        border-top: 1px solid #D9D9D9;
        transition: 0.3s ease left;
        height: 100vh;
    }

    .app-layout .mobile-nav-open {
        left: 0 !important;
        /*box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.4);
        -webkit-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.4); */
    }
}

@media print {

    .app-layout header,
    .app-layout nav,
    button {
        display: none !important;
    }
}