.one-notify {
    display: flex;
    margin: 16px 0;
}
.one-notify:first-of-type {
    margin-top: 6px;
}
.one-notify label {
    width: 60%;
}
.one-notify input {
    margin-top: 0;
}

.one-notify button {
    background: none;
    border: 0;
    margin-left: 12px;
}

.one-contact-info {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #CECECE;
}
.one-contact-info:last-of-type {
    border-bottom: 0;
}
.one-contact-info h3,
.one-contact-info p{
    margin: 4px 0;
}
