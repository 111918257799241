.auth-layout {
    padding-top: 10vh;
    width: 25%;
    margin: auto;
}
.auth-layout > img {
  display: block;
  margin: auto;
}
.auth-layout h1 {
  margin-top: 32px;
  text-align: center;
}

.auth-layout-actions {
    display: flex;
    flex-direction: row-reverse;
}

@media only screen and (max-width: 600px) {
    .auth-layout {
        width: 90% !important;
    }

    .auth-layout-actions {
        display: flex;
        flex-direction: column-reverse;
    }
    .auth-layout-actions button {
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .auth-layout {
        width: 50%;
    }
}

@media only screen and (max-width: 1200px) {
    .auth-layout {
        width: 35%;
    }
}
