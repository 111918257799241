.one-document {
  display: flex;
  align-items: flex-start;
}
.one-document label {
  flex-grow: 1;
  margin: 0;
}
.one-document input {
  margin-top: 0;
}
.one-document > button,
.one-document > a {
  margin-left: 12px;
}
