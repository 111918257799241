.print-table {
  border-collapse: collapse;
}

.print-table,
.print-table th,
.print-table td {
  border: 1px solid;
  min-height: 20px;
}
.print-table th,
.print-table td {
    padding: 8px;
    height: 20px;
}
.print-table th {
    font-size: 14px;
    font-weight: bold;
    color: black;
}
.print-table td {
    font-weight: normal;
    font-size: 12px;
}

@media only screen and (max-width: 800px) {
    .print-table th {
        font-size: 18px;
        font-weight: bold;
        color: black;
    }
}
