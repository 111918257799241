.address-container {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
}

.address-container .city-state-zip {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 12px;
}

.address-container .city-state-zip label:first-child {
    flex-grow: 1;
}
