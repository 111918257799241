.customer-search {
    max-width: 250px;
    position: relative;
}
.customer-search svg {
    position: absolute;
    left: 8px;
    top: 9px;
}

.customer-search input {
    padding-left: 32px;
}
