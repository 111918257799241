.dropdown-container {
    position: relative;
    text-align: right;
}
.dropdown-container > button svg {
    margin-left: 8px;
}

.dropdown-container ul {
    position: absolute;
    width: 200px;
    background: #fff;
    top: calc(100% - 1px);
    right: 0;
    box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.4);
    margin: 0;
    border: 1px solid #000;
    padding: 0;
    z-index: 10;
}

.dropdown-container ul li {
    list-style-type: none;
    padding: 5px;
    cursor: pointer;
}
.dropdown-container ul li:hover {
    background: #eae9ff;
}
.dropdown-container ul li button,
.dropdown-container ul li a {
    background: none;
    border: none;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #000;
    line-height: 25px;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
    display: block;
}
