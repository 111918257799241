.phone-number-group {
    padding-top: 32px;
    border-top: 1px solid #ccc;
}
.phone-number-group .phone-number {
    border-bottom: 1px solid #ccc;
    padding: 32px 0;
    margin: 32px 0;
}
.phone-number-group .phone-number label {
    font-size: 14px;
}
.phone-number-group .phone-number button {
    display: block;
    margin-left: auto;
    font-size: 14px;
}
.phone-number-group .phone-number:first-of-type {
    margin-top: 0;
    padding-top: 8px;
}
.phone-number-group .phone-number:last-child {
    border-bottom: none;
}
