.send-message-modal .selected-contacts {
    padding-left: 0;
    font-size: 12px;
    max-height: 115px;
    overflow-y: scroll;
}
.send-message-modal .selected-contacts li {
    display: flex;
    justify-content: space-between;
    padding: 6px;
    align-items: center;
}
.send-message-modal .selected-contacts li:hover {
    background-color: #eee;
}
.send-message-modal .selected-contacts button {
    background: none;
    border: none;
}
.send-message-modal .existing-warranties,
.existing-messages {
    margin: 0;
}
.send-message-modal .existing-warranties li p,
.existing-messages li p {
    margin: 0;
}
.send-message-modal .existing-warranties li,
.existing-messages li {
    margin-bottom: 16px;
}
.existing-messages li {
    margin-bottom: 16px;
}
.send-message-modal summary > h4 {
    display: inline-block;
    margin: 0 0 10px 0;
}
.send-message-modal details > summary {
    cursor: pointer;
}

.send-message-modal > form {
    padding-top: 16px;
    border-top: 1px solid #DDD;
}
.send-message-modal input[type="number"] {
    max-width: 250px;
}

.contact-results {
    max-height: 100px;
    overflow-y: scroll;
}
.contact-results button {
    text-align: left;
    font-size: 14px;
    width: calc(100% - 20px);
    background: #EEE;
    border: 0;
    padding: 10px;
    margin-top: 8px;
}
