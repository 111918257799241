:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.auth-layout {
  width: 25%;
  margin: auto;
  padding-top: 10vh;
}

.auth-layout > img {
  margin: auto;
  display: block;
}

.auth-layout h1 {
  text-align: center;
  margin-top: 32px;
}

.auth-layout-actions {
  flex-direction: row-reverse;
  display: flex;
}

@media only screen and (width <= 600px) {
  .auth-layout {
    width: 90% !important;
  }

  .auth-layout-actions {
    flex-direction: column-reverse;
    display: flex;
  }

  .auth-layout-actions button {
    width: 100%;
  }
}

@media only screen and (width <= 900px) {
  .auth-layout {
    width: 50%;
  }
}

@media only screen and (width <= 1200px) {
  .auth-layout {
    width: 35%;
  }
}

label {
  margin-bottom: 16px;
  font-size: 16px;
  display: block;
}

label .error {
  color: #8b0000;
  min-height: 16px;
  display: block;
}

label small {
  font-size: 12px;
}

input, textarea, select, .react-date-picker__inputGroup {
  background: #efefef;
  border: 1px solid #000;
  width: calc(100% - 16px);
  height: 40px;
  margin-top: 6px;
  padding-left: 12px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: block;
}

select {
  width: 100%;
}

textarea {
  resize: vertical;
  min-height: 128px;
  padding-top: 10px;
  padding-bottom: 10px;
}

input::placeholder, textarea::placeholder {
  color: #c7c7c7;
  font-weight: 400;
}

input:disabled, input[disabled], textarea:disabled, textarea[disabled], select:disabled, select[disabled] {
  cursor: not-allowed;
  opacity: .6;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

input[type="file"] {
  background: #f7f6ff;
  border: 2px dashed #10086d;
  width: calc(100% - 52px);
  padding: 12px 24px;
}

.checkbox-label {
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.address-input-container {
  position: relative;
}

.address-input-container .address-options {
  z-index: 10;
  background: #fff;
  border: 1px solid #ccc;
  position: absolute;
  top: 82%;
}

.address-input-container .address-options .suggestion {
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  font-size: 16px;
  line-height: 32px;
}

.address-input-container .address-options .suggestion:hover {
  color: #10086d;
  background: #eae9ff;
}

.date-picker {
  display: flex;
}

.date-picker input:nth-child(2) {
  margin-left: 0;
  padding-left: 0;
}

.date-picker input {
  background: none;
  border: none;
  min-width: 50px;
  margin-top: 0;
}

.date-picker .react-date-picker__inputGroup__year {
  min-width: 70px;
  margin-right: 25px;
}

.date-picker .react-date-picker__inputGroup {
  align-items: center;
  display: flex;
}

.date-picker .react-date-picker__calendar, .date-picker .react-date-picker__calendar-button {
  display: none;
}

.date-picker .react-date-picker__clear-button {
  background: none;
  border: none;
  position: absolute;
  top: 16px;
  right: 0;
}

.date-picker .react-date-picker__wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.app-layout {
  background: #f7f6ff;
  height: 100vh;
  display: flex;
}

.app-layout header {
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.app-layout header .mobile-toggle-nav {
  background: none;
  border: 0;
  margin-left: 24px;
  display: none;
}

.app-layout header img {
  margin-left: 22px;
  margin-right: 10px;
}

.app-layout header span {
  color: #636184;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 500;
}

.app-layout header button:last-child {
  color: #060047;
  background: none;
  border: none;
  border-left: 1px solid #d9d9d9;
  height: 60px;
  padding: 0 42px;
  font-size: 18px;
  font-weight: 500;
}

.app-layout nav, .app-layout .app-content {
  margin-top: 60px;
}

.app-layout nav {
  background: #fff;
  width: 225px;
  min-width: 225px;
  padding-top: 40px;
}

.app-layout nav a {
  color: #636184;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.app-layout nav a span {
  margin-left: 8px;
  display: flex;
  white-space: nowrap !important;
}

.app-layout nav a span .count {
  color: #555;
  margin-left: 8px;
  font-size: 12px;
}

.app-layout nav a.active {
  color: #10086d;
  font-weight: bold;
}

.app-layout nav a.active svg path {
  fill: #10086d;
}

.app-layout .app-content {
  flex-grow: 1;
  padding: 24px;
}

.app-layout .app-content h1 {
  margin-top: 0;
}

@media only screen and (width <= 992px) {
  .app-layout .app-content {
    padding: 12px;
  }

  .app-layout header {
    justify-content: space-between;
  }

  .app-layout header .mobile-toggle-nav {
    display: block;
  }

  .app-layout header img, .app-layout header span {
    display: none;
  }

  .app-layout nav {
    z-index: 100;
    border-top: 1px solid #d9d9d9;
    height: 100vh;
    transition: left .3s;
    position: fixed;
    left: -225px;
  }

  .app-layout .mobile-nav-open {
    left: 0 !important;
  }
}

@media print {
  .app-layout header, .app-layout nav, button {
    display: none !important;
  }
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  border-radius: .25rem;
  width: 100%;
  line-height: 1;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.react-loading-skeleton:after {
  content: " ";
  display: var(--pseudo-element-display);
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  height: 100%;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none;
  }
}

.app-page {
  background: #fff;
  margin-bottom: 150px;
  padding: 16px;
}

.app-page-header {
  justify-content: space-between;
  display: flex;
}

.app-page-section {
  border-top: 1px solid #b4b4b4;
  margin-top: 64px;
  padding-top: 64px;
}

.app-page-section:first-child, .app-page-section .app-page-section {
  margin-top: 0;
  padding-top: 0;
  border-top: none !important;
}

.app-page-section h2 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 20px;
}

.form-page {
  flex-direction: row;
  height: calc(100vh - 182px);
  display: flex;
}

.form-page .form-page-side-nav {
  border-right: 1px solid #c5c5c5;
  width: 180px;
  margin-right: 24px;
  padding-top: 32px;
  padding-right: 8px;
}

.form-page > form {
  flex-grow: 1;
  margin-top: 32px;
  padding-bottom: 128px;
  overflow-y: scroll;
}

.form-page > form .app-page-section h2 {
  z-index: 2;
  background: #fff;
  position: sticky;
  top: 0;
}

.form-page .form-page-side-nav a {
  color: #636184;
  white-space: pre;
  height: 40px;
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.form-section {
  padding-left: 40%;
}

.section-actions {
  justify-content: flex-end;
  margin-bottom: 24px;
  display: flex;
}

.section-actions > * {
  margin-left: 12px;
}

.app-card {
  background: #fff;
  width: calc(50% - 56px);
  min-height: 315px;
  margin: 12px;
  padding: 16px;
  display: inline-block;
}

.app-card h2 {
  margin-top: 0;
  font-weight: 500;
}

@media only screen and (width <= 1200px) {
  .form-section {
    padding-left: 0%;
  }

  .form-page > form {
    padding-bottom: 170px;
  }
}

@media only screen and (width <= 768px) {
  .form-section {
    padding-left: 0%;
  }

  .form-page .form-page-side-nav {
    display: none;
  }
}

.dashboard-container {
  flex-wrap: wrap;
  display: flex;
}

.dashboard-container .app-card {
  flex-grow: 1;
}

.dashboard-container .app-card tr td:last-child {
  text-align: right;
}

.dashboard-container .app-card:first-child, .dashboard-container .app-card:nth-child(3) {
  margin-left: 0;
}

.dashboard-container .app-card:nth-child(2), .dashboard-container .app-card:nth-child(4) {
  margin-right: 0;
}

@media only screen and (width <= 1200px) {
  .dashboard-container .app-card {
    min-height: unset;
    width: calc(100% - 56px);
    margin: 12px 0;
  }
}

@media only screen and (width <= 600px) {
  .dashboard-container .app-card tr td, .dashboard-container .app-card tr th {
    font-size: 12px;
  }
}

.print-container {
  max-width: 100vw;
}

.print-container > button {
  margin: 3%;
}

.print-header {
  align-items: flex-start;
  margin-bottom: 5%;
  display: flex;
}

.print-header .info {
  margin-left: 2%;
}

.print-header p {
  margin: 0;
}

.print-header .qr-code {
  text-align: right;
  flex-grow: 1;
}

.print-content {
  flex-flow: wrap;
  align-items: flex-start;
  display: flex;
}

.print-content .print-table {
  flex-basis: 48%;
  width: 48%;
  margin: 1%;
}

@media only screen and (width <= 800px) {
  .print-header {
    flex-direction: column;
    margin: 5% 2%;
  }

  .print-header .logo {
    margin: auto;
  }

  .print-header h3 {
    font-size: 16px;
  }

  .print-header .qr-code {
    display: none;
  }

  .print-content .print-table {
    flex-basis: 96%;
    width: 96%;
    margin: 0 auto 5%;
  }
}

.ReactModal__Overlay {
  z-index: 11;
  background-color: #050228bd !important;
}

.ReactModal__Content > h2 {
  margin-top: 0;
}

.modal-actions {
  justify-content: flex-end;
  min-width: 400px;
  margin-top: 24px;
  display: flex;
}

.modal-actions button {
  margin-left: 8px;
}

@media only screen and (width <= 600px) {
  .modal-actions {
    flex-direction: column;
    min-width: 80vw;
  }

  .modal-actions button {
    margin-top: 8px;
    margin-left: 0;
  }
}

.dropdown-container {
  text-align: right;
  position: relative;
}

.dropdown-container > button svg {
  margin-left: 8px;
}

.dropdown-container ul {
  z-index: 10;
  background: #fff;
  border: 1px solid #000;
  width: 200px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  box-shadow: 4px 4px 14px #0006;
}

.dropdown-container ul li {
  cursor: pointer;
  padding: 5px;
  list-style-type: none;
}

.dropdown-container ul li:hover {
  background: #eae9ff;
}

.dropdown-container ul li button, .dropdown-container ul li a {
  color: #000;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-decoration: none;
  display: block;
}

.empty-text {
  color: #d3d3d3;
  justify-content: center;
  align-items: center;
  height: 256px;
  display: flex;
}

@media only screen and (width <= 1100px) {
  .table-container {
    min-width: 800px;
    overflow-x: scroll;
  }
}

.view-contacts {
  color: #060047;
  font-size: initial;
  background: none;
  border: 0;
  text-decoration: underline;
}

.customer-search {
  max-width: 250px;
  position: relative;
}

.customer-search svg {
  position: absolute;
  top: 9px;
  left: 8px;
}

.customer-search input {
  padding-left: 32px;
}

.one-notify {
  margin: 16px 0;
  display: flex;
}

.one-notify:first-of-type {
  margin-top: 6px;
}

.one-notify label {
  width: 60%;
}

.one-notify input {
  margin-top: 0;
}

.one-notify button {
  background: none;
  border: 0;
  margin-left: 12px;
}

.one-contact-info {
  border-bottom: 1px solid #cecece;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.one-contact-info:last-of-type {
  border-bottom: 0;
}

.one-contact-info h3, .one-contact-info p {
  margin: 4px 0;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  cursor: pointer;
  border: 1px solid #0000;
  border-bottom: none;
  padding: 6px 12px;
  list-style: none;
  display: inline-block;
  position: relative;
  bottom: -1px;
}

.react-tabs__tab--selected {
  color: #000;
  background: #fff;
  border-color: #aaa;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  background: #fff;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: -4px;
  right: -4px;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.phone-number-group {
  border-top: 1px solid #ccc;
  padding-top: 32px;
}

.phone-number-group .phone-number {
  border-bottom: 1px solid #ccc;
  margin: 32px 0;
  padding: 32px 0;
}

.phone-number-group .phone-number label {
  font-size: 14px;
}

.phone-number-group .phone-number button {
  margin-left: auto;
  font-size: 14px;
  display: block;
}

.phone-number-group .phone-number:first-of-type {
  margin-top: 0;
  padding-top: 8px;
}

.phone-number-group .phone-number:last-child {
  border-bottom: none;
}

.floating-submit {
  z-index: 5;
  background: #fff;
  border-top: 1px solid #10086d;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 20px 15%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 34px #00000040;
}

.floating-submit > div button {
  margin-left: 8px;
}

@media only screen and (width <= 768px) {
  .floating-submit {
    text-align: center;
    flex-direction: column-reverse;
    height: 100px;
    padding: 20px 5%;
  }

  .floating-submit a, .floating-submit button {
    width: 100%;
    margin: 8px 0 0;
  }
}

.address-container {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

.address-container .city-state-zip {
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 12px;
  display: flex;
}

.address-container .city-state-zip label:first-child {
  flex-grow: 1;
}

.send-message-modal .selected-contacts {
  max-height: 115px;
  padding-left: 0;
  font-size: 12px;
  overflow-y: scroll;
}

.send-message-modal .selected-contacts li {
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  display: flex;
}

.send-message-modal .selected-contacts li:hover {
  background-color: #eee;
}

.send-message-modal .selected-contacts button {
  background: none;
  border: none;
}

.send-message-modal .existing-warranties, .existing-messages, .send-message-modal .existing-warranties li p, .existing-messages li p {
  margin: 0;
}

.send-message-modal .existing-warranties li, .existing-messages li, .existing-messages li {
  margin-bottom: 16px;
}

.send-message-modal summary > h4 {
  margin: 0 0 10px;
  display: inline-block;
}

.send-message-modal details > summary {
  cursor: pointer;
}

.send-message-modal > form {
  border-top: 1px solid #ddd;
  padding-top: 16px;
}

.send-message-modal input[type="number"] {
  max-width: 250px;
}

.contact-results {
  max-height: 100px;
  overflow-y: scroll;
}

.contact-results button {
  text-align: left;
  background: #eee;
  border: 0;
  width: calc(100% - 20px);
  margin-top: 8px;
  padding: 10px;
  font-size: 14px;
}

.status-event {
  display: flex;
}

.status-event label {
  margin-left: 12px;
}

.status-event label:first-child {
  width: 60%;
  margin-left: 0;
}

.status-event label:last-of-type {
  flex-grow: 1;
}

.status-event button {
  background: none;
  border: none;
  margin: 0 16px;
}

.one-manufacturer {
  gap: 8px;
  display: flex;
}

.one-manufacturer button, .one-manufacturer input {
  height: 45px;
  margin-top: 0;
  font-size: 14px;
}

.one-manufacturer input {
  height: 41px;
}

button.edit-manufacturers {
  margin-bottom: 16px;
  font-size: 12px;
}

.assign-technicians .one-assigned-technician {
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.assign-technicians .one-assigned-technician button {
  vertical-align: middle;
  background: none;
  border: none;
  margin: 0;
  padding-top: 4px;
  padding-left: 0;
  padding-right: 12px;
}

.print-table {
  border-collapse: collapse;
}

.print-table, .print-table th, .print-table td {
  border: 1px solid;
  min-height: 20px;
}

.print-table th, .print-table td {
  height: 20px;
  padding: 8px;
}

.print-table th {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

.print-table td {
  font-size: 12px;
  font-weight: normal;
}

@media only screen and (width <= 800px) {
  .print-table th {
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }
}

.service-interval {
  align-items: center;
  margin-top: 6px;
  display: flex;
}

.service-interval > span {
  font-weight: bold;
}

.service-interval input, .service-interval label {
  height: 40px;
  margin-bottom: 0;
}

.service-interval label {
  margin-top: 0;
  margin-left: 12px;
}

.service-interval select {
  height: 42px;
}

.service-interval select, .service-interval input {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.service-interval label:first-of-type {
  width: 56px;
}

.service-interval label:last-of-type {
  flex-grow: 1;
}

.service-interval .error {
  display: none;
}

.service-interval label {
  width: 40%;
}

.one-document {
  align-items: flex-start;
  display: flex;
}

.one-document label {
  flex-grow: 1;
  margin: 0;
}

.one-document input {
  margin-top: 0;
}

.one-document > button, .one-document > a {
  margin-left: 12px;
}

html, body {
  background: #f7f6ff;
  margin: 0;
  font-family: Inter, sans-serif;
}

button {
  cursor: pointer;
}

button:disabled, button[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

.primary, .secondary {
  white-space: pre;
  min-width: 125px;
  padding: 7px 22px;
  font-size: 16px;
  font-weight: 600;
}

.primary {
  color: #fff;
  background: #10086d;
  border: 1px solid #10086d;
}

.secondary {
  color: #10086d;
  background: #eae9ff;
  border: 1px solid #10086d;
}

table {
  width: 100%;
}

table th {
  text-align: left;
  color: #707070;
  font-size: 16px;
  font-weight: 500;
}

table td {
  color: #060047;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
}

.react-tabs__tab button {
  vertical-align: middle;
  background: 0;
  border: 0;
  width: 16px;
  margin-left: 12px;
}

@media only screen and (width <= 768px) {
  .primary, .secondary {
    font-size: 18px;
  }
}

/*# sourceMappingURL=index.f2c7543a.css.map */
