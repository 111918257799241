.assign-technicians .one-assigned-technician {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.assign-technicians .one-assigned-technician button {
    background: none;
    border: none;
    margin: 0;
    padding-top: 4px;
    padding-left: 0;
    padding-right: 12px;
    vertical-align: middle;
}
